import { postToMesServer } from './common';

/**
 * admin授权管理分页查询
 * @param {*} obj 
 */
export async function authPagingAdmin(obj) {
  return await postToMesServer('/productmanagement/Admin/PagingAdmin', obj);
}
/**
 * admin发起授权
 * @param {*} obj 
 */
export async function authAdminAdd(obj) {
  return await postToMesServer('/productmanagement/Admin/Add', obj);
}
/**
 * admin日志列表
 * @param {*} obj 
 */
export async function authAdminLog(obj) {
  return await postToMesServer('/productmanagement/Admin/PagingAdminLog', obj);
}
/**
 * admin 撤销授权
 * @param {*} obj 
 */
export async function updateAuthStatus(obj) {
  return await postToMesServer('/productmanagement/Admin/Update', obj);
}
/**
 * admin 获得企业列表
 * @param {*} obj 
 */
export async function getOrgList(obj) {
  return await postToMesServer('/productmanagement/Admin/GetOrgList', obj);
}
/**
 * admin 修改密码
 * @param {*} obj 
 */
export async function updatePassword(obj) {

  return await postToMesServer('/productmanagement/Admin/UpdatePassword', obj);
}
/**
 * admin 修改密码
 * @param {*} obj 
 */
export async function adminLogin(obj) {

  return await postToMesServer('/productmanagement/Admin/Logon', obj);
}

/**
 * admin 群智设备分页
 * @param {*} obj 
 */
export async function adminGetDevices(obj) {

  return await postToMesServer('/xservice/Xservice/XServerAdminPaging', obj);
}

/**
 * admin 群智设备增改
 * @param {*} obj 
 */
export async function adminUpdateDevices(obj) {

  return await postToMesServer('/xservice/Xservice/UpdateXServerAdmin', obj);
}

/**
 * admin 群智设备删除
 * @param {*} obj 
 */
export async function adminDelDevices(obj) {

  return await postToMesServer('/xservice/Xservice/DelXServerAdmin', obj);
}

/**
 * admin 群智设备获取公司下设备
 * @param {*} obj 
 */
export async function adminGetCompanyDevices(obj) {

  return await postToMesServer('/xservice/Xservice/Paging', obj);
}

/**
 * admin 群智设备获取报警信息
 * @param {*} obj 
 */
export async function adminGetDeviceWarnPaging(obj) {

  return await postToMesServer('/xservice/Xservice/DeviceWarnPaging', obj);
}

/**
 * admin 群智设备获取运行日志
 * @param {*} obj 
 */
export async function adminGetDeviceLogPaging(obj) {

  return await postToMesServer('/xservice/Xservice/GetDeviceStatusPaging', obj);
}

/**
 * admin 群智设备刷新设备参数
 * @param {*} obj 
 */
export async function adminRefreshEquParam(obj) {

  return await postToMesServer('/xservice/Xservice/RefreshEquParam', obj);
}


export async function adminGetDeviceDetail(obj) {
  return await postToMesServer('/xservice/Xservice/GetDeviceDetail', obj);
}

export async function AdminUpdateDeviceDetail(obj) {
  return await postToMesServer('/xservice/Xservice/UpdateDeviceDetail', obj);
}