<template>
  <div style="height: 100%">
    <template v-if="!isDetail">
      <div class="page-header">
        群智网络控制器状态
        <div class="color-1">总设备数：{{ allCount }}</div>
        <div class="color-2">在线数：{{ onConut }}</div>
        <div class="color-3">离线数：{{ offCount }}</div>
      </div>
      <div class="tab flex">
        <div class="flex-1" style="padding: 10px 20px">
          <label style="margin-right: 10px">企业名称</label>
          <el-input
            placeholder="请输入企业名称"
            style="width: 180px; margin-right: 10px"
            v-model="search.commpanyName"
          ></el-input>
          <label style="margin-right: 10px">状态</label>
          <el-select
            placeholder="请选择状态"
            style="width: 180px; margin-right: 10px"
            v-model="search.xServiceState"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="在线" value="0"></el-option>
            <el-option label="离线" value="-1"></el-option>
          </el-select>
          <el-button
            @click="searchList()"
            style="
              width: 80px;
              position: relative;
              left: -4px;
              margin-right: 10px;
            "
            type="primary"
            >搜索</el-button
          >
          <el-button type="primary" @click="toAdd">添加</el-button>
          <el-button
            type="danger"
            @click="toDelete"
            :disabled="selectedRows.length === 0"
            >删除</el-button
          >
          <el-button
            type="primary"
            @click="toEdit"
            :disabled="selectedRows.length !== 1"
            >编辑</el-button
          >
        </div>
      </div>
      <div class="block" style="height: calc(100% - 120px); margin: 0 20px">
        <el-table
          :data="deviceData"
          height="calc(100% - 50px)"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            type="selection"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="commpanyName"
            label="企业名称"
            align="center"
          ></el-table-column>
          <el-table-column prop="iccid" label="物联网卡号" align="center">
            <template slot-scope="scope">
              <a
                href="https://console.usr.cn/sim/#/sim/first/Viewindex/Viewindex"
                >{{ scope.row.iccid }}</a
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="xService"
            label="群智网络控制器编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="xServiceState"
            label="群智网络控制器状态"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.xServiceState === '-1'" style="color: red"
                >离线</span
              >
              <span v-else style="color: green">在线</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="compAdder"
            label="企业地址"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="influxdbTable"
            label="InfluxDB表"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <a @click="toDetail(scope.row)">详细信息</a>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="pageSettings.page"
          :page-size="pageSettings.pagesize"
          :total="pageSettings.total"
          style="margin-top: 8px"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
    </template>
    <!-- 详细信息 -->
    <template v-else>
      <div class="page-header">
        {{ detail.data.commpanyName }}
        <el-button type="primary" @click="handleBack" class="btn-back"
          >返回</el-button
        >
      </div>
      <div class="block" style="height: calc(100% - 80px); margin: 20px 20px 0">
        <el-table :data="detail.tableData" height="calc(100% - 50px)">
          <el-table-column
            type="index"
            label="序号"
            width="60"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="commpanyName"
            label="企业名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="deviceCode"
            label="设备编号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="deviceName"
            label="设备名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="deviceBrand"
            label="设备品牌"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="warningInfo"
            label="报警信息"
            align="center"
          ></el-table-column>
          <el-table-column prop="" label="设备状态" align="center">
            <template slot-scope="scope">
              <span v-if="scope.row.xServiceState === '0'" style="color: green"
                >在线</span
              >
              <span v-else style="color: red">离线</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="dailyElectricity"
            label="设备日用电"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center" width="280">
            <template slot-scope="scope">
              <a @click="toDeviceDetail(scope.row)">设备详情</a>
              <a @click="toParameters(scope.row)" style="margin-left: 10px"
                >设备参数</a
              >
              <a @click="toWarnNote(scope.row)" style="margin-left: 10px"
                >报警日志</a
              >
              <a @click="toRunNote(scope.row)" style="margin-left: 10px"
                >运行日志</a
              >
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="detailPageSettings.page"
          :page-size="detailPageSettings.pagesize"
          :total="detailPageSettings.total"
          style="margin-top: 8px"
          @current-change="handleDetailCurrentChange"
        ></el-pagination>
      </div>
    </template>
    <!-- 添加修改弹窗 -->
    <el-dialog
      width="1090px"
      :title="dialog1.title"
      :visible.sync="dialog1.visible"
      :destroy-on-close="true"
    >
      <el-form
        :model="dialog1.form"
        :rules="dialog1.rules"
        ref="dialog1Form"
        label-width="140px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="企业名称" prop="commpanyName">
              <el-input
                v-model="dialog1.form.commpanyName"
                placeholder="请输入企业名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业ID" prop="orgId">
              <el-input
                v-model="dialog1.form.orgId"
                placeholder="请输入企业ID"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="向日葵编号" prop="sunflowerCode">
              <el-input
                v-model="dialog1.form.sunflowerCode"
                placeholder="请输入向日葵编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="物联网卡号" prop="iccid">
              <el-input
                v-model="dialog1.form.iccid"
                placeholder="请输入物联网卡号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="群智网络控制编号" prop="xService">
              <el-input
                v-model="dialog1.form.xService"
                placeholder="请输入群智网络控制编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="状态" prop="xServiceState">
              <el-select
                placeholder="请选择状态"
                style="width: 180px; margin-right: 10px"
                v-model="dialog1.form.xServiceState"
              >
                <el-option label="在线" value="0"></el-option>
                <el-option label="离线" value="-1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业地址" prop="compAdder">
              <el-input
                v-model="dialog1.form.compAdder"
                placeholder="请输入企业地址"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="influxdb表" prop="influxdbTable">
              <el-input
                v-model="dialog1.form.influxdbTable"
                placeholder="请输入influxdb表"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电量" prop="dailyElectricity">
              <el-input
                v-model="dialog1.form.dailyElectricity"
                placeholder="请输入电量"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="备注" prop="remark">
              <el-input
                v-model="dialog1.form.remark"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="btn-wrap" style="text-align: center">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </div>
    </el-dialog>

    <!-- 报警日志 -->
    <el-dialog
      :title="dialog4.title"
      :visible.sync="dialog4.visible"
      :before-close="closeDialog"
      width="1090px"
    >
      <div style="margin-bottom: 10px">
        <label for="">开始时间：</label>
        <el-date-picker
          size="small"
          v-model="dialog4.search.startTime"
          type="datetime"
          placeholder="选择开始时间"
          style="margin-right: 20px"
          value-format="yyyy-MM-DD HH:mm:ss"
          format="yyyy-MM-DD HH:mm:ss"
        >
        </el-date-picker>
        <label for="">结束时间：</label>
        <el-date-picker
          size="small"
          v-model="dialog4.search.endTime"
          type="datetime"
          placeholder="选择结束时间"
          style="margin-right: 20px"
          value-format="yyyy-MM-DD HH:mm:ss"
          format="yyyy-MM-DD HH:mm:ss"
        >
        </el-date-picker>
        <label for="">状态：</label>
        <el-select
          size="small"
          v-model="dialog4.search.factState"
          placeholder="请选择状态"
          style="margin-right: 20px"
        >
          <el-option
            v-for="item in dialog4.warnStateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-button type="primary" @click="getWarnList(1)" size="small"
          >查询</el-button
        >
      </div>
      <el-table
        ref="warnTable"
        :data="dialog4.tableData"
        border
        class="height_60"
        height="400"
        :row-class-name="tableRowClassName"
      >
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column prop="duration" label="累计时间" align="center" />
        <el-table-column
          prop="runTime"
          label="开始时间"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{ scope.row.runTime.replace("T", " ") }}
          </template>
        </el-table-column>

        <el-table-column
          prop="warningInfo"
          label="告警信息"
          align="center"
          show-overflow-tooltip
        />
        <el-table-column prop="duration" label="累计时间" align="center" />
        <el-table-column label="状态" align="center" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.factState == 0">复位</span>
            <span v-if="scope.row.factState == 1">故障</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @current-change="handleWarnPaginationChange"
        :current-page.sync="dialog4PageSettings.page"
        :page-size="dialog4PageSettings.pageSize"
        background
        layout="prev, pager, next,->,total"
        :total="dialog4PageSettings.total"
      >
      </el-pagination>
      <br />
      <div style="text-align: center">
        <el-button @click="closeDialog" size="small btn_style3">
          取消
        </el-button>
      </div>
    </el-dialog>

    <!-- 运行日志 -->
    <el-dialog
      title="运行日志"
      :visible.sync="dialog5.visible"
      :before-close="closeDialog"
      width="1090px"
    >
      <div style="margin-bottom: 10px">
        <label for="">开始时间：</label>
        <el-date-picker
          size="small"
          v-model="dialog5.search.startTime"
          type="datetime"
          placeholder="选择开始时间"
          style="margin-right: 20px"
          value-format="yyyy-MM-DD HH:mm:ss"
          format="yyyy-MM-DD HH:mm:ss"
        >
        </el-date-picker>
        <label for="">结束时间：</label>
        <el-date-picker
          size="small"
          v-model="dialog5.search.endTime"
          type="datetime"
          placeholder="选择结束时间"
          style="margin-right: 20px"
          value-format="yyyy-MM-DD HH:mm:ss"
          format="yyyy-MM-DD HH:mm:ss"
        >
        </el-date-picker>
        <label for="">状态：</label>
        <el-select
          size="small"
          v-model="dialog5.search.factState"
          placeholder="请选择状态"
          style="margin-right: 20px"
        >
          <el-option
            v-for="item in dialog5.logStateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>

        <el-button type="primary" @click="getLogList(1)" size="small"
          >查询</el-button
        >
      </div>
      <el-table
        ref="logTable"
        :data="dialog5.tableData"
        border
        class="height_60"
        height="400"
        :row-class-name="tableRowClassName2"
      >
        <el-table-column type="index" label="序号" align="center" width="60" />
        <el-table-column label="状态" align="center" width="80">
          <template slot-scope="scope">
            <span v-if="scope.row.factState == 0">待机</span>
            <span v-if="scope.row.factState == 1">运行故障</span>
            <span v-if="scope.row.factState == 2">运行</span>
            <span v-if="scope.row.factState == 3">离线</span>
            <span v-if="scope.row.factState == 4">未知</span>
            <span v-if="scope.row.factState == 5">故障</span>
            <span v-if="scope.row.factState == 6">控制器未连接</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="runTime"
          label="开始时间"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            {{ scope.row.runTime.replace("T", " ") }}
          </template>
        </el-table-column>
        <el-table-column prop="duration" label="累计时间" align="center" />
      </el-table>
      <el-pagination
        @current-change="handleLogPaginationChange"
        :current-page.sync="dialog5PageSettings.page"
        :page-size="dialog5PageSettings.pageSize"
        background
        layout="prev, pager, next,->,total"
        :total="dialog5PageSettings.total"
      >
      </el-pagination>

      <br />
      <div style="text-align: center">
        <el-button @click="closeDialog" size="small btn_style3">
          取消
        </el-button>
      </div>
    </el-dialog>
    <!-- 设备参数 -->
    <el-dialog
      :title="dialog3.title"
      :visible.sync="dialog3.visible"
      :before-close="closeDialog"
      width="700px"
    >
      <table v-loading="dialog3.loading" class="device-table">
        <tr>
          <td colspan="4">
            {{ dialog3.title }}
          </td>
        </tr>
        <tr v-for="(item, i) in dialog3.device_data" :key="i">
          <td>{{ item[0] }}</td>
          <td>{{ item[1] }}</td>
          <td>{{ item[2] }}</td>
          <td>{{ item[3] }}</td>
        </tr>
      </table>
      <div style="text-align: center; margin-top: 20px">
        <el-button
          type="primary"
          @click="getDeviceData"
          size="small btn_style1"
        >
          刷新
        </el-button>
        <el-button @click="closeDialog" size="small btn_style3">
          关闭
        </el-button>
      </div>
    </el-dialog>

    <!-- 设备详情 -->
    <el-dialog
      title="设备详情"
      :visible.sync="dialog6.visible"
      :before-close="closeDialog"
      width="800px"
    >
      <el-form :model="dialog6.data">
        <el-row>
          <el-col :span="12">
            <el-form-item label="设备编号：">
              <el-input
                placeholder="请输入设备编号"
                style="width: 250px"
                v-model="dialog6.data.deviceCode"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称：">
              <el-input
                placeholder="请输入设备名称"
                style="width: 250px"
                v-model="dialog6.data.deviceName"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备品牌：">
              <el-input
                placeholder="请输入设备品牌"
                style="width: 250px"
                v-model="dialog6.data.deviceBrand"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备型号：">
              <el-input
                placeholder="请输入设备型号"
                style="width: 250px"
                v-model="dialog6.data.deviceModel"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备相数：">
              <el-input
                placeholder="请输入设备相数"
                style="width: 250px"
                v-model="dialog6.data.devicePhase"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备频率：">
              <el-input
                placeholder="请输入设备频率"
                style="width: 250px"
                v-model="dialog6.data.deviceFrequency"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备功率：">
              <el-input
                placeholder="请输入设备功率"
                style="width: 250px"
                v-model="dialog6.data.deviceWatts"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备重量：">
              <el-input
                placeholder="请输入设备重量"
                style="width: 250px"
                v-model="dialog6.data.deviceWeight"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="外观尺寸：">
              <el-input
                placeholder="请输入外观尺寸"
                style="width: 250px"
                v-model="dialog6.data.deviceSize"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="出厂日期：">
              <el-date-picker
                v-model="dialog6.data.deviceDate"
                type="datetime"
                placeholder="请选择出厂日期"
                style="width: 250px"
                value-format="yyyy-MM-DD"
                format="yyyy-MM-DD"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div style="text-align: center; margin-top: 20px">
        <el-button
          type="primary"
          @click="saveDeviceDetail"
          size="small btn_style1"
        >
          保存
        </el-button>
        <el-button @click="closeDialog" size="small btn_style3">
          关闭
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  adminGetDevices,
  adminUpdateDevices,
  adminDelDevices,
  adminGetCompanyDevices,
  adminGetDeviceWarnPaging,
  adminGetDeviceLogPaging,
  adminRefreshEquParam,
  adminGetDeviceDetail,
  AdminUpdateDeviceDetail,
} from "@/service/mes";
import moment from "moment";
export default {
  data() {
    return {
      allCount: 1,
      offCount: 1,
      onConut: 5,
      search: {
        commpanyName: "",
        xServiceState: "",
      },
      deviceData: [],
      selectedRows: [],
      pageSettings: {
        page: 1,
        pageSize: 15,
        total: 0,
      },
      dialog1: {
        visible: false,
        title: "添加",
        form: {
          id: "",
          orgId: "",
          tenantId: 0,
          commpanyName: "", //企业名称
          sunflowerCode: "", //向日葵编号
          iccid: "", //物联网卡号
          xService: "", //群智网络控制编号
          xServiceState: "0", //状态 0 在线 -1 离线
          compAdder: "", //企业地址
          influxdbTable: "", //表(不显示)
          dailyElectricity: "", //电量(不显示)
          remark: "", //备注
        },
        rules: {
          orgId: [{ required: true, message: "请输入orgId", trigger: "blur" }],
          commpanyName: [
            { required: true, message: "请输入公司名称", trigger: "blur" },
          ], //企业名称
          sunflowerCode: [
            { required: true, message: "请输入向日葵编号", trigger: "blur" },
          ], //向日葵编号
          iccid: [
            { required: true, message: "请输入物联网卡号", trigger: "blur" },
          ], //物联网卡号
          xService: [
            {
              required: true,
              message: "请输入群智网络控制器编号",
              trigger: "blur",
            },
          ], //群智网络控制编号
          xServiceState: [
            { required: true, message: "请选择状态", trigger: "" },
          ],
          compAdder: [
            { required: true, message: "请输入企业地址", trigger: "blur" },
          ],
          dailyElectricity: [
            { required: true, message: "请输入电量", trigger: "blur" },
          ],
          remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
          influxdbTable: [
            { required: true, message: "请输入influxdb表", trigger: "blur" },
          ], //表(不显示)
        },
      },
      isDetail: false,
      detail: {
        data: {},
        tableData: [],
      },
      detailPageSettings: {
        page: 1,
        pageSize: 15,
        total: 0,
      },
      dialog3: {
        visible: false,
        title: "",
        row: {},
        loading: false,
        device_data: [],
      },
      dialog4: {
        visible: false,
        title: "",
        row: {},
        search: {
          startTime: "",
          endTime: "",
          factState: "",
        },
        warnStateOptions: [
          {
            label: "全部",
            value: "",
          },
          {
            label: "复位",
            value: 0,
          },
          {
            label: "故障",
            value: 1,
          },
        ],
        tableData: [],
      },
      dialog4PageSettings: {
        page: 1,
        pageSize: 15,
        total: 0,
      },
      dialog5: {
        visible: false,
        title: "",
        row: {},
        search: {
          startTime: "",
          endTime: "",
          factState: "",
        },
        logStateOptions: [
          {
            label: "全部",
            value: "",
          },
          {
            label: "待机",
            value: 0,
          },
          {
            label: "运行故障",
            value: 1,
          },
          {
            label: "运行",
            value: 2,
          },
          {
            label: "离线",
            value: 3,
          },
          {
            label: "未知",
            value: 4,
          },
          {
            label: "故障",
            value: 5,
          },
          {
            label: "未连接",
            value: 6,
          },
        ],
        tableData: [],
      },
      dialog5PageSettings: {
        page: 1,
        pageSize: 15,
        total: 0,
      },
      dialog6: {
        visible: false,
        data: {
          deviceCode: "", // 设备编号
          deviceName: "", // 设备名称
          deviceBrand: "", // 设备品牌
          deviceModel: "", //  设备型号
          devicePhase: "", //  设备相数
          deviceFrequency: "", //   设备频率
          deviceWatts: "", //   设备功率
          deviceWeight: "", //设备重量
          deviceSize: "", //外观尺寸
          deviceDate: "", //出厂日期
          influxdbTable: "", //数据表（必填）
        },
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (row.factState == 0) {
        return "green";
      }
      if (row.factState == 1) {
        return "red";
      }
    },
    tableRowClassName2({ row, rowIndex }) {
      if (row.factState == 0) {
        return "yellow";
      }
      if (row.factState == 1) {
        return "red";
      }
      if (row.factState == 2) {
        return "green";
      }
    },
    handleBack() {
      this.isDetail = false;
      this.detail = { data: {}, tableData: [] };
      this.detailPageSettings.page = 1;
    },
    toDeviceDetail(row) {
      this.getDeviceDetal(row.influxdbTable);
      this.dialog6.visible = true;
    },
    async getDeviceDetal(influxdbTable) {
      const res = await adminGetDeviceDetail({ influxdbTable });
      if (res.success) {
        if (res.content) {
          this.dialog6.data = res.content;
        }
      }
      this.dialog6.data.influxdbTable = influxdbTable;
    },
    async saveDeviceDetail() {
      const res = await AdminUpdateDeviceDetail(this.dialog6.data);
      if (res.success) {
        this.$message.success("保存成功");
        this.getDetailList();
        this.closeDialog();
      }
    },
    toParameters(row) {
      this.dialog3.visible = true;
      this.dialog3.row = row;
      this.dialog3.title = `${row.deviceName}设备参数`;
      this.getDeviceData();
    },
    async getDeviceData() {
      this.dialog3.loading = true;
      const res = await adminRefreshEquParam({
        influxdbTable: this.dialog3.row.influxdbTable,
      });
      if (res.success) {
        let content = res.content;
        this.dialog3.device_data = this.parseDeviceData(
          content ? JSON.parse(content) : []
        );
        this.dialog3.loading = false;
      }
    },
    parseDeviceData(data) {
      const arr = [];
      if (data.length === 0) {
        return arr;
      }
      arr.push([]);
      data.map((n) => {
        const entries = Object.entries(n);
        if (arr[arr.length - 1].length < 4) {
          arr[arr.length - 1].push(entries[0][0]);
          arr[arr.length - 1].push(entries[0][1]);
        } else {
          const arr2 = [];
          arr2.push(entries[0][0]);
          arr2.push(entries[0][1]);
          arr.push(arr2);
        }
      });
      return arr;
    },
    toWarnNote(row) {
      this.dialog4.visible = true;
      this.dialog4.row = row;
      this.dialog4.title = row.deviceName + "的报警日志";
      this.getWarnList();
    },
    handleWarnPaginationChange(page) {
      this.getWarnList(page);
    },
    async getWarnList(page) {
      const search = {
        influxdbTable: this.dialog4.row.influxdbTable,
        startTime: this.dialog4.search.startTime,
        endTime: this.dialog4.search.endTime,
        FactState: this.dialog4.search.factState,
      };
      if (
        this.dialog4.search.startTime === "" &&
        this.dialog4.search.endTime === ""
      ) {
        const now = moment();
        const hour = now.hour();
        let next;
        if (hour < 8) {
          now.subtract(1, "days");
        }
        now.hour(8);
        now.minute(0);
        now.second(0);
        now.millisecond(0);
        next = now.clone();
        next.add(1, "days");
        search.startTime = now.format("yyyy-MM-DD HH:mm:ss");
        search.endTime = next.format("yyyy-MM-DD HH:mm:ss");
      }
      page = page || this.dialog4PageSettings.page;
      const data = {
        search,
        pageIndex: page,
        pageSize: this.dialog4PageSettings.pageSize,
        noCount: false,
        orderBy: "",
        countSql: "",
      };
      const res = await adminGetDeviceWarnPaging(data);
      if (res.success) {
        let content = res.content;
        this.dialog4.tableData = content.rows;
        this.dialog4PageSettings.page = page;
        this.dialog4PageSettings.total = res.content.total;
      }
    },
    toRunNote(row) {
      this.dialog5.visible = true;
      this.dialog5.row = row;
      this.dialog5.title = row.deviceName + "的运行日志";
      this.getLogList();
    },
    handleLogPaginationChange(page) {
      this.getLogList(page);
    },
    async getLogList(page) {
      const search = {
        influxdbTable: this.dialog5.row.influxdbTable,
        startTime: this.dialog5.search.startTime,
        endTime: this.dialog5.search.endTime,
        FactState: this.dialog5.search.factState,
      };
      if (
        this.dialog5.search.startTime === "" &&
        this.dialog5.search.endTime === ""
      ) {
        const now = moment();
        const hour = now.hour();
        let next;
        if (hour < 8) {
          now.subtract(1, "days");
        }
        now.hour(8);
        now.minute(0);
        now.second(0);
        now.millisecond(0);
        next = now.clone();
        next.add(1, "days");
        search.startTime = now.format("yyyy-MM-DD HH:mm:ss");
        search.endTime = next.format("yyyy-MM-DD HH:mm:ss");
      }
      page = page || this.dialog5PageSettings.page;
      const data = {
        search,
        pageIndex: page,
        pageSize: this.dialog5PageSettings.pageSize,
        noCount: false,
        orderBy: "",
        countSql: "",
      };
      const res = await adminGetDeviceLogPaging(data);
      if (res.success) {
        let content = res.content;
        this.dialog5.tableData = content.rows;
        this.dialog5PageSettings.page = page;
        this.dialog5PageSettings.total = res.content.total;
      }
    },
    handleSelectionChange(rows) {
      this.selectedRows = rows;
    },
    handleCurrentChange(page) {
      this.getList(page);
    },
    searchList() {
      this.getList(1);
    },
    async getList(page) {
      page = page || this.pageSettings.page;
      const data = {
        search: this.search,
        pageIndex: page,
        pageSize: 15,
        noCount: false,
        orderBy: "",
        countSql: "",
      };
      const res = await adminGetDevices(data);
      if (res.success) {
        this.deviceData = res.content.rows;
        this.pageSettings.total = res.content.total;
        this.pageSettings.page = page;
        this.allCount = res.content.allCount;
        this.offCount = res.content.offCount;
        this.onConut = res.content.onConut;
      }
    },
    toAdd() {
      this.dialog1.visible = true;
      this.dialog1.title = "添加";
    },
    async toDetail(row) {
      this.isDetail = true;

      this.detail.data = row;
      this.getDetailList();
    },
    handleDetailCurrentChange(page) {
      this.getDetailList(page);
    },
    async getDetailList(page) {
      page = page || this.detailPageSettings.page;
      const data = {
        search: {
          orgId: this.detail.data.orgId,
        },
        pageIndex: page,
        pageSize: 15,
        noCount: false,
        orderBy: "",
        countSql: "",
      };
      const res = await adminGetCompanyDevices(data);
      if (res.success) {
        this.detail.tableData = res.content.rows;
        this.detailPageSettings.total = res.content.total;
        this.detailPageSettings.page = page;
      }
    },
    async toDelete() {
      try {
        const result = await this.$confirm(
          "此操作将永久删除该数据, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        );
        if (result) {
          const res = await adminDelDevices(
            this.selectedRows.map((n) => ({ id: n.id }))
          );
          if (res.success) {
            this.$message.success("删除成功");
            this.getList();
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    toEdit() {
      const row = this.selectedRows[0];
      this.dialog1.visible = true;
      this.dialog1.title = "编辑";
      this.dialog1.form = {
        id: row.id,
        orgId: row.orgId,
        tenantId: row.tenantId,
        commpanyName: row.commpanyName, //企业名称
        sunflowerCode: row.sunflowerCode, //向日葵编号
        iccid: row.iccid, //物联网卡号
        xService: row.xService, //群智网络控制编号
        xServiceState: row.xServiceState, //状态 0 在线 -1 离线
        compAdder: row.compAdder, //企业地址
        influxdbTable: row.influxdbTable, //表(不显示)
        dailyElectricity: row.dailyElectricity, //电量(不显示)
        remark: row.remark, //备注
      };
    },
    async save() {
      this.$refs.dialog1Form.validate(async (valid) => {
        if (!valid) {
          return;
        }
        let confirm = true;
        if (this.dialog1.form.id) {
          confirm = false;
          confirm = await this.$confirm("您确认要修改嘛？");
        }
        if (!confirm) {
          return;
        }
        const data = {
          ...this.dialog1.form,
        };
        if (!this.dialog1.form.id) {
          delete data.id;
        }
        const res = await adminUpdateDevices(data);
        this.closeDialog();
        if (res.success) {
          this.$message.success("保存成功");
          this.getList();
        }
      });
    },
    closeDialog() {
      if (this.dialog1.visible) {
        this.dialog1.visible = false;
        this.dialog1.title = "添加";
        this.dialog1.form = {
          id: "",
          orgId: "",
          tenantId: 0,
          commpanyName: "", //企业名称
          sunflowerCode: "", //向日葵编号
          iccid: "", //物联网卡号
          xService: "", //群智网络控制编号
          xServiceState: "0", //状态 0 在线 -1 离线
          compAdder: "", //企业地址
          influxdbTable: "", //表(不显示)
          dailyElectricity: "", //电量(不显示)
          remark: "", //备注
        };
      } else if (this.dialog3.visible) {
        this.dialog3.visible = false;
        this.dialog3.row = {};
        this.dialog3.device_data = [];
      } else if (this.dialog4.visible) {
        this.dialog4.visible = false;
        this.dialog4.row = {};
        this.dialog4.search = { startTime: "", endTime: "", factState: "" };
        this.dialog4.tableData = [];
        this.dialog4PageSettings.page = 1;
      } else if (this.dialog5.visible) {
        this.dialog5.visible = false;
        this.dialog5.row = {};
        this.dialog5.search = { startTime: "", endTime: "", factState: "" };
        this.dialog5.tableData = [];
        this.dialog5PageSettings.page = 1;
      } else if (this.dialog6.visible) {
        this.dialog6 = {
          visible: false,
          data: {
            deviceCode: "", // 设备编号
            deviceName: "", // 设备名称
            deviceBrand: "", // 设备品牌
            deviceModel: "", //  设备型号
            devicePhase: "", //  设备相数
            deviceFrequency: "", //   设备频率
            deviceWatts: "", //   设备功率
            deviceWeight: "", //设备重量
            deviceSize: "", //外观尺寸
            deviceDate: "", //出厂日期
            influxdbTable: "", //数据表（必填）
          },
        };
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-header {
  display: flex;
  align-items: center;
  position: relative;
  > div {
    width: 150px;
    height: 40px;
    background: red;
    margin-right: 60px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    &:first-child {
      margin-left: 100px;
    }
    &.color-1 {
      background-color: #ffcc66;
    }
    &.color-2 {
      background-color: #00ccff;
    }
    &.color-3 {
      background-color: #999999;
    }
  }
}
.el-table {
  color: #000;
  /deep/ .green {
    background: lightgreen;
  }
  /deep/ .red {
    background: red;
  }
  /deep/ .yellow {
    background: yellow;
  }
}
.device-table {
  width: 100%;
  tr:first-child {
    font-weight: bold;
    font-size: 16px;
  }
  td {
    border: 1px solid #ebeef5;
    height: 40px;
    text-align: center;
    color: #333;
  }
}
.btn-back {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translate(-0, -50%);
}
</style>